var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","order":"5"}},[_c('div',{staticClass:"text-center"},[(!Object.keys(_vm.studentsPerYear).length)?_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}}):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[(_vm.activeStudents || _vm.activeGroups || _vm.avgStudentsPrevMonth)?_c('crm-statistics-card',{attrs:{"data":{
          activeStudents: _vm.activeStudents,
          activeGroups: _vm.activeGroups,
          avgStudentsPrevMonth: { count: _vm.avgStudentsPrevMonth, month: _vm.getPrevMonthText() },
        }}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"8","sm":"8"}},[(Object.keys(_vm.studentsPerYear).length)?_c('line-chart',{attrs:{"title":"Matriculación Anual","chartLabel":"Matricula/s","type":"line","xaxis":Object.keys(_vm.studentsPerYear[_vm.selectedYear]),"data":Object.keys(_vm.studentsPerYear[_vm.selectedYear]).map(s => _vm.studentsPerYear[_vm.selectedYear][s]),"select":{ value: _vm.selectedYear, items: Object.keys(_vm.studentsPerYear) }},on:{"onSelect":v => (_vm.selectedYear = v)}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"4"}},[(_vm.finalizedGroups)?_c('table-chart',{attrs:{"title":"Pendientes grupos finalizados","tableHeaders":[
          { text: 'Grupo', value: 'name' },
          { text: 'Calificaciónes', value: 'pendingQualifications' },
          { text: 'Asistencias', value: 'pendingDayAttendances' },
        ],"tableItems":_vm.finalizedGroups,"itemsPerPageText":"Grupos por página","noDataText":"Sin datos de grupos"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[(_vm.activeStudentsPrevMonth.length)?_c('line-chart',{attrs:{"title":'Alumnos Activos/día ' + _vm.getPrevMonthText(),"chartLabel":"Activos","type":"area","xaxis":_vm.activeStudentsPrevMonth.map((a, i) => i + 1),"data":_vm.activeStudentsPrevMonth}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }