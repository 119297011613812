<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" order="5">
        <div class="text-center">
          <v-progress-circular
            v-if="!Object.keys(studentsPerYear).length"
            :size="70"
            :width="7"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>

      <v-col cols="12" md="12" sm="12">
        <crm-statistics-card
          v-if="activeStudents || activeGroups || avgStudentsPrevMonth"
          :data="{
            activeStudents,
            activeGroups,
            avgStudentsPrevMonth: { count: avgStudentsPrevMonth, month: getPrevMonthText() },
          }"
        ></crm-statistics-card>
      </v-col>

      <v-col cols="12" md="8" sm="8">
        <line-chart
          v-if="Object.keys(studentsPerYear).length"
          title="Matriculación Anual"
          chartLabel="Matricula/s"
          type="line"
          :xaxis="Object.keys(studentsPerYear[selectedYear])"
          :data="Object.keys(studentsPerYear[selectedYear]).map(s => studentsPerYear[selectedYear][s])"
          :select="{ value: selectedYear, items: Object.keys(studentsPerYear) }"
          @onSelect="v => (selectedYear = v)"
        ></line-chart>
      </v-col>

      <v-col cols="12" md="4" sm="4">
        <table-chart
          v-if="finalizedGroups"
          title="Pendientes grupos finalizados"
          :tableHeaders="[
            { text: 'Grupo', value: 'name' },
            { text: 'Calificaciónes', value: 'pendingQualifications' },
            { text: 'Asistencias', value: 'pendingDayAttendances' },
          ]"
          :tableItems="finalizedGroups"
          itemsPerPageText="Grupos por página"
          noDataText="Sin datos de grupos"
        ></table-chart>
      </v-col>

      <v-col cols="12">
        <line-chart
          v-if="activeStudentsPrevMonth.length"
          :title="'Alumnos Activos/día ' + getPrevMonthText()"
          chartLabel="Activos"
          type="area"
          :xaxis="activeStudentsPrevMonth.map((a, i) => i + 1)"
          :data="activeStudentsPrevMonth"
        ></line-chart>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import useDashboards from './analytics/useDashboards'

// Charts
import CrmStatisticsCard from './analytics/CrmStatisticsCard.vue'
import TableChart from './analytics/TableChart.vue'
import LineChart from './analytics/LineChart.vue'

import { onUnmounted, ref, inject } from 'vue'

export default {
  components: {
    CrmStatisticsCard,
    TableChart,
    LineChart,
  },
  setup(emit) {
    const date = inject('date')
    const selectedYear = ref(date().format('YYYY'))
    const {
      fetchDashboards,
      activeGroups,
      activeStudents,
      finalizedGroups,
      studentsPerYear,
      avgStudentsPrevMonth,
      activeStudentsPrevMonth,
    } = useDashboards()

    fetchDashboards()

    const getPrevMonthText = () => {
      const month = date().locale('es').subtract(1, 'month').format('MMMM')
      return month.substring(0, 1).toUpperCase() + month.substring(1, month.length)
    }

    return {
      getPrevMonthText,
      avgStudentsPrevMonth,
      selectedYear,
      finalizedGroups,
      activeStudents,
      activeGroups,
      studentsPerYear,
      activeStudentsPrevMonth,
    }
  },
}
</script>
