<template>
  <v-card>
    <v-card-title class="align-start flex-nowrap">
      <p class="text-sm font-weight-semibold mb-2">
        {{ $props.title }}
      </p>
    </v-card-title>

    <v-data-table
      :headers="$props.tableHeaders"
      :items="$props.tableItems"
      :options="{ itemsPerPage: 5 }"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-text': `${$props.itemsPerPageText}`,
        'items-per-page-options': [5],
      }"
      class="table-cursor"
    >
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
      </template>
      <template v-slot:no-data> {{ $props.noDataText }} </template>
    </v-data-table>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiDotsVertical, mdiChevronUp } from '@mdi/js'

export default {
  props: {
    title: { type: String, default: '' },
    tableHeaders: { type: Array, default: [] },
    tableItems: { type: Array, default: [] },
    itemsPerPageText: { type: String, default: '' },
    noDataText: { type: String, default: '' },
  },
  setup() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
      },
    }
  },
}
</script>
