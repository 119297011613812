import store from '@/store'
import { onUnmounted, ref } from 'vue'
import analyticsStoreModule from './analyticsStoreModule'

export default function useDashboards() {
  const appAnalytics = 'app-analytics'

  // Register module
  if (!store.hasModule(appAnalytics)) store.registerModule(appAnalytics, analyticsStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(appAnalytics)) store.unregisterModule(appAnalytics)
  })

  const activeGroups = ref(null)
  const activeStudents = ref(null)
  const finalizedGroups = ref(null)
  const studentsPerYear = ref({})
  const avgStudentsPrevMonth = ref(null)
  const activeStudentsPrevMonth = ref([])

  // fetch data
  const fetchDashboards = async () => {
    const resp = await store.dispatch('app-analytics/fetchDashboards', {})
    activeGroups.value = resp.data.activeGroups
    activeStudents.value = resp.data.activeStudents
    finalizedGroups.value = resp.data.finalizedGroups
    studentsPerYear.value = resp.data.studentsPerYear
    avgStudentsPrevMonth.value = resp.data.avgStudentsPrevMonth
    activeStudentsPrevMonth.value = resp.data.activeStudentsPrevMonth
  }

  return {
    fetchDashboards,
    finalizedGroups,
    activeGroups,
    activeStudents,
    studentsPerYear,
    avgStudentsPrevMonth,
    activeStudentsPrevMonth,
  }
}
