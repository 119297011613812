<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col v-for="data in statisticsData" :key="data.title" cols="6" md="2" sm="6" class="d-flex align-center">
          <v-avatar size="44" :color="data.color" rounded class="elevation-1">
            <v-icon dark color="white" size="30">
              {{ data.icon }}
            </v-icon>
          </v-avatar>

          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-bold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountMultipleOutline, mdiSchoolOutline } from '@mdi/js'
import { inject } from 'vue'

export default {
  props: {
    data: { type: Object, default: {} },
  },
  setup(props) {
    const statisticsData = [
      {
        title: 'Alumnos Activos',
        total: props.data.activeStudents,
        icon: mdiSchoolOutline,
        color: 'primary',
      },
      {
        title: 'Grupos Activos',
        total: props.data.activeGroups,
        icon: mdiAccountMultipleOutline,
        color: 'primary',
      },
      {
        title: 'Promedio Alumnos ' + props.data.avgStudentsPrevMonth.month,
        total: props.data.avgStudentsPrevMonth.count,
        icon: mdiAccountMultipleOutline,
        color: 'primary',
      },
    ]

    return {
      statisticsData,

      // icons
      icons: {},
    }
  },
}
</script>
